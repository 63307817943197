import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import styled from "styled-components"
import Intro from "../components/Work/Intro"
import Project from "../components/Work/Project"
import PreFooter from "../components/Core/PreFooter"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import { Link } from "gatsby"
import ContentContainer from "../components/Core/ContentContainer"
import { Container, Row, Col } from "styled-bootstrap-grid"
import CategoryFilter from "../components/Core/CategoryFilter"
import BlockContainer from "../components/Core/BlockContainer"

const WorkPageContainer = styled.div`
  margin-top: 142px;
  max-width: 2000px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 175px;
  }
`

const WorkPage = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const filteredProjects = []
  const projects = props.data.projects.nodes
  let slugs = []

  projects.forEach((project) => {
    if (!slugs.includes(project.slug)) {
      slugs.push(project.slug)
      filteredProjects.push(project)
    }
  })

  const sortedProjects = filteredProjects.sort((a, b) => a.order - b.order)

  return (
    <Layout darkStyle={true}>
      <SEO
        fullTitle={true}
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <CategoryFilter projectCount={sortedProjects.length}/>
      <BlockContainer>
        <WorkPageContainer>
          <Container fluid>
            <ContentContainer>
              <Row>
                <Col sm={6} lg={5} lgOffset={1} xxlOffset={0}>
                  <Intro title={page.title} description={page.description} />
                </Col>
                <Col lg={12}>
                  {sortedProjects.map((project, index) => {
                    return (
                      <Project key={index} project={project} isEven={index % 2} />
                    )
                  })}
                  <PreFooter />
                </Col>
              </Row>
            </ContentContainer>
          </Container>
        </WorkPageContainer>
      </BlockContainer>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query WorkQuery {
    page: contentfulPage(slug: { eq: "projets" }) {
      title
      description
      metaTitle
      metaDescription
    }
    projects: allContentfulProject {
      nodes {
        title
        metaTitle
        metaDescription
        slug
        imageHeight
        order
        category {
          slug
          title
        }
        thumbnails {
          fluid(maxWidth: 1104, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
